@import "color-palette";

.second-link {
  text-decoration: none;
  background-image: linear-gradient(to right, $bright-gray 75%, transparent 75%);
  background-position: 0 95%;
  background-repeat: repeat-x;
  background-size: 7px 1px;
  color: $bright-gray;
  cursor: pointer;
  word-break: break-all;

  &.disabled {
    pointer-events: none;
    background-image: linear-gradient(to right, $regent-gray 75%, transparent 75%);
    color: $regent-gray;
  }
}

.second-link:hover,
.second-link:focus {
  color: $bright-gray;
  text-decoration: none;
  background: none;
}

.archived-link, .deactivated-link {
  background-image: linear-gradient(to right, $regent-gray 75%, transparent 75%);
  color: $regent-gray;

  &::before {
    font-family: "FontAwesome", sans-serif;
    font-weight: 400;
    font-size: 14px;
    content: "\f187";
    padding-right: 5px;
  }

  &:hover,
  &:focus {
    color: $regent-gray;
    text-decoration: none;
    background: none;
  }
}

.deactivated-link::before {
    font-size: 20px;
    content: "\f056";
}

.robinson-customer {
  color: #e0f;
}

.main-link {
  text-decoration: none;
  background-image: linear-gradient(to right, $cerulean 75%, transparent 75%);
  background-position: 0% 95%;
  background-repeat: repeat-x;
  background-size: 7px 1px;
  color: $cerulean;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    background-image: linear-gradient(to right, $regent-gray 75%, transparent 75%);
    color: $regent-gray;
  }

  &.archived-link, &.deactivated-link {
    background-image: linear-gradient(to right, $regent-gray 75%, transparent 75%);
    color: $regent-gray;

    &:hover {
      color: $regent-gray;
      text-decoration: none;
      background: none;
    }
  }
}

.is-main {
  color: $cerulean;
}

.main-link:hover,
.main-link:focus {
  color: $cerulean;
  text-decoration: none;
  background: none;
}

.btn-second-link {
  background: transparent;
  border: none;
  outline: none;
}
