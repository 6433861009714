@import 'src/assets/scss/color-palette';

.menu {
  position: absolute;
  min-width: 100%;
  width: max-content;
  max-height: 216px;
  z-index: 10;
  overflow-y: auto;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
}

.option {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background: $white;
  border: none;
  border-top: 1px solid $light-porcelain;
  cursor: pointer;

  &:hover {
    background-color: $light-porcelain;
  }
}

.header {
  padding: 10px;
  color: $regent-gray;
  font-size: 14px;
}
