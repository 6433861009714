@import 'src/assets/scss/color-palette';

.modal {
  max-width: 600px;
  margin: 30px auto;

  :global {
    button {
      &:focus,
      &:active {
        outline: none;
      }
    }

    .modal-header {
      padding: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow: hidden;

      &__top {
        width: 100%;
        padding: 20px;
        background: $snow-drift;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .modal-title.h4 {
          color: $tuna;
          font-weight: 400;
          font-size: 20px;
          line-height: 1;
        }

        button {
          font-size: 20px;
        }
      }
    }

    .modal-body {
      padding: 0;

      &__main {
        .add-note-field {
          padding: 20px;
        }
      }

      &__bottom {
        display: flex;
        justify-content: space-between;
        text-align: left;
        padding: 20px;
        border-top: 1px solid $light-gray;

        .button {
          min-width: 160px;
          padding: 0 30px;
        }
      }

      &__nodes-list {
        margin-top: 0;
        padding: 20px;
        overflow-y: auto;
        max-height: 500px;

        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: $porcelain;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: $light-gray;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: $tower-gray;
        }

        .notes-item {
            margin-bottom: 10px;
            font-weight: 400;
            border-radius: 5px;
            background-color: $snow-drift;
            padding: 15px;

          &:last-child {
                margin-bottom: 0;
          }

          h4 {
            font-size: 20px;
            margin-bottom: 12px;
          }

          .header {
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            color: $regent-gray;

            .postDate {
                display: flex;
                
                .deleteBtn {
                    display: flex;
                    align-items: center;
                    height: auto;
                    padding: 0;
                    margin: -2px 0 0 10px;
                }
            }
        }

        .linkWrapper {
            display: flex;

            .dispatcherName {
                max-width: 70%;
                .link {
                    color: $bright-gray;
                    
                    &:not(:disabled) {
                        text-decoration: underline;
                    }
                }
            }

            .companyName {
                color: $nevada;
            }
        }

          &__important {
            background: $seashell-peach;
            border: 1px solid $yellow-orange;
          }
        }
      }
    }
  }
}
