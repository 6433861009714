@import 'assets/scss/color-palette';

.title {
  font-weight: 700;
  text-transform: uppercase;
}

.description {
  margin-left: 10px;
  color: $regent-gray;
}

.dot {
  display: block;
  width: 5px;
  height: 5px;
  background-color: $light-porcelain;
}

.clear {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
