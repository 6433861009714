.name {
  width: 270px;
}

.prefix {
  width: 80px;
}

.address {
  width: 275px;
}
