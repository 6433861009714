@import 'color-palette';

.view-table {
  width: 100%;
}

.view-table td {
  padding: 10px 0;
  vertical-align: top;
}

.view-left h2 {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 40px;
}

.view-left p {
  font-weight: 300;
}

.view-left.form-content .tab-content {
  margin-bottom: 0;
}

.view-left .nav-tabs {
  background: $snow-drift;
  padding-top: 30px;
}

.view-left .nav-tabs > a.active,
.view-left .nav-tabs > a:hover.active,
.view-left .nav-tabs > a:focus.active {
  background: $snow-drift;
  border: none;
  border-bottom: 3px solid $dodger-blue;
  color: $tuna;
  outline: none;
}

.view-left .nav > a {
  background: $snow-drift;
  border: none;
  color: $regent-gray;
  margin: 0 25px;
  outline: none;
  padding: 5px 10px;

  &,
  &:hover,
  &:focus {
    &.tab_highlight {
      &_error {
        background-color: $fair-pink;

        &.active {
          border-bottom: 3px solid $sunset-orange;
        }
      }

      &_warning {
        background-color: $seashell-peach;

        &.active {
          border-bottom: 3px solid $crusta;
        }
      }
    }
  }
}

.view-left .nav-tabs > a:hover {
  background: $snow-drift;
  border: none;
  color: $regent-gray;
  margin: 0 25px;
  padding: 5px 10px;
}

.view-left .files-outer {
  margin: 30px 20px;
}

.view-left .view-table td b {
  font-weight: 400;
  text-transform: uppercase;
}

.view-left .view-table td {
  font-weight: 300;
}

.view-table tr td:first-child {
  width: 160px;
}

.box-styled {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);

  &.steps {
    margin: 20px 0 0;
  }
}

.view-right h2 {
  color: $cerulean;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: 20px;
}

.view-right {
  font-size: 14px;

  .box-styled {
    min-width: 350px;
    padding: 50px 0;
  }
}

.view-right .driver-status,
.view-right .active-status {
  margin-bottom: 10px;

  & span {
    border-radius: 7px;
    color: $white;
    padding: 5px 20px;
  }
}

.view-btn-group {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.box-wrap {
  margin: 20px;
}

.block-header {
  height: 75px;
  overflow: hidden;
}

.view-avatar-img {
  height: 160px;
  margin: 0 auto;
  object-fit: cover;
  width: 160px;
}

tr.with-border {
  border-bottom: 1px solid $porcelain;
}

.box-overflow-hidden {
  overflow: hidden;
}

.view-inner-box {
  margin: 25px auto;
  width: 75%;

  hr {
    border: 0;
    border-top: 1px solid $porcelain;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.highlight {
  &_error,
  &_warning {
    padding: 2px 5px;
    border-radius: 3px;
    position: relative;
  }

  &_error {
    background-color: $fair-pink;
    border: 1px solid $sunset-orange;
  }

  &_warning {
    background-color: $seashell-peach;
    border: 1px solid $crusta;
  }
}
