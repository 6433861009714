@import 'assets/scss/color-palette';

.wrap {
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border: 1px solid $yellow-orange;
  color: $yellow-orange;
  border-radius: 2px;
  font-size: 14px;
}
