// color names were taken from http://chir.ag/projects/name-that-color/

//------------------------------------------------------
//      primary colors
//------------------------------------------------------
$custom-blue:	  #088bd6;
$light-blue:	  #40c4ff;
$cerulean:        #039be5;
$dodger-blue:     #3fc4ff;
$spindle:         #bdd7ed;
$link-water:      #e2eef6;
$lily-white:      #ecf9ff;
$pattens-blue:    #d9f3ff;

//------------------------------------------------------
//      secondary colors
//------------------------------------------------------
$shark:           #212529;
$tuna:            #2f333e;
$bright-gray:     #353a45;
$tundora:         #424242;
$trout:           #505861;
$rolling-stone:   #7a8185;
$nevada:          #5e6c73;
$gray:            #939393;
$regent-gray:     #84939a;
$tower-gray:      #b0bec6;
$alto:            #d0d0d0;
$light-porcelain: #eceff1;
$porcelain:       #e5e8e9;
$seashell-peach:  #fff6ed;
$light-gray:      #ebeff2;
$black-haze:      #eef0f0;
$snow-drift:      #f7f9f8;
$white:           #fff;
$mercury:         #e5e5e5;
$white-pointer:   #fde2ff;
$electric-violet: #ee00ff;
$loblolly:        #c3ced3;
$provincial-pink:   #fef6ee;

//------------------------------------------------------
//      accent colors
//------------------------------------------------------
$jungle-green:    #27af6d;
$shamrock:        #2bc97c;
$loafer:          #e5f9f0;
$fair-pink:       #ffeded;
$coconut-cream:   #faf4e0;
$yellow-orange:   #ffab40;
$rajah:           #f8ad6b;
$orange: 		  #f99d2c;
$sunset-orange:   #ff5253;
$your-pink:       #ffbaba;
$jungle-orange:   #e23334;
$monza:           #d80027;
$french-lilac:    #ece1f7;
$onahau:          #c9eeff;
$crusta:          #ff8933;
$frangipani:      #ffddb3;
$emerald:         #55d496;

//------------------------------------------------------
//      caravan colors
//------------------------------------------------------
$sunglow:         #ffcc32;
$sun:             #fdb913;
$black-strong:    #000;
$black:           #050505;
$silver-chalice:  #a7a7a7;
$alabaster:       #fafafa;
$thunder:         #231f20;
$thunder-strong:  #2d2c2d;
$rhino:           #2a3c5f;
$green-haze:      #06a350;
