.searchRow {
  display: flex;
  justify-content: space-between;

  :global {
    button {
      text-transform: none !important;
    }
  }
}

.searchField {
  display: flex;
  flex-grow: 0.98;
}
