@import 'src/assets/scss/color-palette';

.modal {
    :global {
        .modal-dialog {
            width: 600px;
        }
    }
    
    .login {
        display: flex;
        margin-top: 20px;
        color: $bright-gray;
    }

    .choices {
        display: flex;

        button {
            background: none;
            border: none;
        }

        .radio {
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: middle;
            position: relative;
            border-radius: 50%;
            background-color: #f7f9f8;
            border: 1px solid #b0bfc6;

            &::before {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: transparent;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }

            &.checked {
                &::before {
                    background-color: $bright-gray;
                  }
            }
        }

        label {
            margin-left: 5px;
        }
    }
}