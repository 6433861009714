@import 'assets/scss/color-palette';

.table {
  margin-top: 15px;
  border: 1px solid $light-porcelain;
  border-bottom: 0;
  border-radius: 5px;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $snow-drift;

    tr th {
      padding: 15px 20px !important;
    }
  }

  tr td {
    padding: 25px 20px !important;
  }

  .pointer {
    cursor: pointer;
  }

  .applied {
    i {
      color: $dodger-blue;
    }
  }
}

.scroll {
  max-height: calc(100vh - 317px);
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: $porcelain;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $snow-drift;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $tower-gray;
  }
}
