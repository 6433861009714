@import "assets/scss/color-palette";

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > div {
    margin-bottom: 10px;
  }

  .title {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 14px;

    .icon {
      margin-right: 5px;
      color: $regent-gray;

      &.some {
        color: $cerulean;
      }
    }

    .text {
      font-weight: 500;
      text-transform: uppercase;
      color: $bright-gray;
    }
  }

  .results {
    margin-right: 10px;
    color: $tundora;
    font-size: 16px;
  }

  .filter {
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    background-color: $white;
    border: 1px solid $tower-gray;
    border-radius: 4px;

    .label {
      margin-right: 7px;
      color: $regent-gray;
      font-size: 16px;
    }

    .value {
      margin-right: 7px;
      font-weight: 700;
      font-size: 14px;
      color: $bright-gray;
    }

    .cross {
      font-size: 16px;
      color: $regent-gray;
      cursor: pointer;
      transition: 0.6s;

      &:hover {
        color: $nevada;
        transform: scale(1.2);
      }
    }
  }

  .clear {
    cursor: pointer;
    margin-left: 20px;
    color: $tuna;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
  }
}
