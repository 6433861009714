@import 'assets/scss/color-palette';

.table {
  margin-top: 20px;
  border: 1px solid $light-porcelain;
  border-bottom: 0;
  border-radius: 5px;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $snow-drift;

    tr th {
      padding: 15px 20px !important;
    }
  }

  tr td {
    padding: 25px 20px !important;

    &:nth-of-type(1) > div {
      width: 270px;
    }

    &:nth-of-type(2) > div {
      width: 80px;
    }

    &:nth-of-type(3) > div {
      width: 350px;
    }
  }
}

.scroll {
  max-height: calc(100vh - 440px);
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: $porcelain;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $snow-drift;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $tower-gray;
  }
}
