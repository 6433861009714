@import 'assets/scss/color-palette';

.row {
  position: relative;

  :global {
    .iw_cb_shell {
      white-space: wrap;

      label {
        margin-left: 5px;
      }
    }
  }
}

.row__disabled {
  background-color: $snow-drift;

  :global {
    .iw_cb_shell {
      label {
        color: $regent-gray;
      }

      &.disabled {
        opacity: 1;
      }
    }
  }

  td > div {
    color: $regent-gray;
  }
}
