@import "src/assets/scss/color-palette";

.empty_notes {
    color: $regent-gray;
    background: $snow-drift;
    margin: 20px;
    padding: 10px;
}

.list {
    padding: 0 20px;
    margin: 20px 0;
    max-height: calc(100% - 80px);
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: $light-gray;
    }

    &::-webkit-scrollbar-thumb {
        background: $regent-gray;
    }

    .item {
        border: 1px solid $snow-drift;
        background: $snow-drift;
        padding: 15px;
        margin-bottom: 10px;

        .header {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 5px;

            .desc {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            .type {
                font-weight: 500;
                text-transform: uppercase;
            }
    
            .link {
                margin-right: 5px;
            }

            .companyName {
                font-weight: 500;
            }
    
            .time, .companyName {
                color: $regent-gray;
            }
        }

        .content {
            color: $tuna;
            font-size: 16px;
            word-break: break-all;
            white-space: normal;
        }
    }
}
