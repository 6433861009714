@import 'src/assets/scss/color-palette';

.modal {
    :global {
        .modal-dialog {
            width: 600px;
        }
    }
}

.login {
    display: flex;
    margin-top: 20px;
    color: $bright-gray;
}
